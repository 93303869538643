import { Alert, AlertTitle, Theme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { enUS } from "date-fns/locale";
import us from "date-fns/locale/en-US";
import { useAtom } from "jotai";
import ModalProvider from "mui-modal-provider";
import Head from "next/head";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { isImpersonating } from "src/consts/shadow.consts";
import { AppTheme } from "../AppTheme";
import { LOCAL_STORAGE_AUTH_REDIRECT, LOCAL_STORAGE_AUTH_REF, LOCAL_STORAGE_AUTH_UTM } from "../consts/storageKeys";
import { useAppContext } from "../context/AppContext";
import { CommandBarContextProvider } from "../context/CommandBarContext";
import { useUserContext } from "../context/UserContext";
import { useWindowEventListener } from "../hooks/useEventListener";
import { useHubspotIdentify } from "../hooks/useHubspotIdentify";
import { useOurRouter } from "../hooks/useOurRouter";
import { windowActiveAtom } from "../hooks/useWindowActive";
import ReclaimErrorBoundary from "../layouts/ReclaimErrorBoundary";
import { QueryState } from "../types/query";
import { setLocalStorage, setSessionStorage } from "../utils/local-storage";
import { browser } from "../utils/platform";
import { getUserOnboardingPath, hasValidRoutesWithoutAuth, useRecordHistory } from "../utils/router";
import classes from "./App.module.scss";
import { DevDrawer } from "./DevDrawer";
import { EasyAccessElements } from "./EasyAccessElements";
import { GlobalStateInitializer } from "./GlobalStateInitializer";

us.options = { weekStartsOn: 1 };

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const REF_PARAM = "utm_term";
const UTM_PARAM_RE = /^utm_*/;

export const App: React.FC = ({ children }) => {
  const router = useOurRouter<{
    op: string; // used by auth
    utm_term?: string;
    utm_campaign?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_content?: string;
    state?: QueryState;
    logout?: string;
    sharedHabit?: string;
    invite?: string;
  }>();

  useRecordHistory(router);
  const [, setWindowActive] = useAtom(windowActiveAtom);
  useWindowEventListener("focus", () => setWindowActive(true));
  useWindowEventListener("blur", () => setWindowActive(false));

  // FIXME (IW): Without this, OAuth params are borked
  // move state to new format for legacy api
  if (typeof router.query.state === "string") {
    router.query.state = JSON.parse(router.query.state || "{}");
  }

  const { state } = useAppContext();
  const [userState, userActions] = useUserContext();

  // this sends the hubspot identify command when user is authenticated
  useHubspotIdentify();

  const [theme, setTheme] = useState<Theme>(state.theme === "dark" ? AppTheme.Dark : AppTheme.Light);
  const medium = useMediaQuery(theme.breakpoints.down("lg"));

  const resizeTimeout = useRef<NodeJS.Timeout | undefined>();

  const adapterLocale = useMemo(() => {
    enUS.options = { weekStartsOn: userState.user?.settings?.weekStart || 0 };
    return enUS;
  }, [userState.user?.settings?.weekStart]);

  /**
   * Set the current app theme (not currently used by anything)
   */
  useEffect(() => {
    setTheme(state.theme === "dark" ? AppTheme.Dark : AppTheme.Light);
  }, [state.theme]);

  /**
   * Refresh current user on route changes
   */
  useEffect(() => {
    // Wait for router to init
    if (!router.isReady) return;

    // Refresh user and team
    if (!!router.query?.logout || ["loading", "failed"].includes(userState.status)) return;

    // This is where the app first initializes top-level data
    void userActions?.load(userState.status === "ok");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, router.pathname]);

  /**
   * Hack to fix vh on mobile browsers
   */
  useEffect(() => {
    if (!browser().isBrowser) return;

    const resizeListener = () => {
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
        resizeTimeout.current = undefined;
      }

      resizeTimeout.current = setTimeout(() => {
        if (!browser().isBrowser) return;
        document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
        document.documentElement.style.setProperty("--vw", `${window.innerWidth * 0.01}px`);
      }, 25);
    };

    if (browser().isBrowser) {
      document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
      document.documentElement.style.setProperty("--vw", `${window.innerWidth * 0.01}px`);

      window.addEventListener("resize", resizeListener);
    }

    return () => {
      if (browser().isBrowser) window.removeEventListener("resize", resizeListener);
    };
  }, []);

  /**
   * Grab referral code to pass along in signup
   */
  useEffect(() => {
    if (!browser().isBrowser) return;

    // stash ref to add to auth state param
    const cookies = document.cookie.split(/;\s*/).reduce((acc: { [key: string]: unknown }, cookie: string) => {
      const [key, val] = cookie.split("=");
      acc[key] = val;
      return acc;
    }, {});

    const ref = cookies["ref"] || router.query?.[REF_PARAM];
    const utm = Object.entries(router.query || {})
      .filter(([k]) => UTM_PARAM_RE.test(k))
      .reduce((acc, [k, v]) => {
        const key = k.replace(/^utm_/, "");
        acc[key] = v;
        return acc;
      }, {});

    if (!!ref) setLocalStorage(LOCAL_STORAGE_AUTH_REF, ref);
    if (!!Object.keys(utm).length) setLocalStorage(LOCAL_STORAGE_AUTH_UTM, utm);
  }, [router.query]);

  /**
   * Main routing logic
   * FIXME (IW): This is wayy too complicated as it is
   */
  useEffect(() => {
    // Dont do any redirect till we know what the heck is going on with the user... render will just show loading
    if (!router.isReady) return;

    // Hard reload the page after logout to make sure cookie is destroyed
    if (!!router.query.logout) {
      window.location.href = "/login";
      return;
    }

    // Wait for user to load
    if (["init", "loading"].includes(userState.status)) return;

    // TODO (IW): Move this to `protectedRoute`
    // Redirect to login if user is not authenticated
    if (!userState.isAuthenticated && userState.status === "failed" && !hasValidRoutesWithoutAuth(router)) {
      setSessionStorage(LOCAL_STORAGE_AUTH_REDIRECT, router.asPath);

      void router.push({
        pathname: "/login",
      });

      return;
    }

    // legacy redirect from invite dialog to /share-reclaim
    if (!!router.query.invite) {
      void router.replace({
        pathname: "/share-reclaim",
        query: {
          ...router.query,
          invite: undefined,
        },
      });

      return;
    }

    // if we have a redirect in the state, use it, really the API should do this so its a little hacky
    // the check for "://" prevents arbitrary redirection (Open Redirect)
    const redirect = router.query.state?.redirect;

    if (!!redirect && !redirect.includes("://")) {
      void router.replace(
        {
          pathname: redirect.startsWith("/onboarding/user") ? "/onboarding/user/[...step]" : redirect,
          query: {
            ...router.query,
            state: { ...router.query.state, redirect: undefined },
          },
        },
        redirect
      );

      return;
    }

    // redirect old smart 1:1 email urls
    // FIXME (IW): Remove at a safe time in the future
    const matches = router.asPath.match(/^\/one-on-ones\/(\d{1,})\?/i);
    if (!!matches?.length) {
      const query = { ...router.query, uid: matches[1] };
      void router.replace({
        pathname: "/smart-onboarding",
        query,
      });
    }

    // if user isn't onboarded, force them back to onboarding
    if (
      !!userState.isAuthenticated &&
      !!userState.user &&
      !userState.user.onboarded &&
      !/^\/(smart\-)?onboarding/.test(router.pathname) &&
      !/^\/(book\-)?meeting/.test(router.pathname) &&
      !/^\/quick-signup/.test(router.pathname) &&
      !/^\/m/.test(router.pathname) &&
      !router.pathname.startsWith("/team/accept")
    ) {
      void router.push({
        pathname: getUserOnboardingPath("welcome"),
        query: router.query,
      });

      return;
    }

    // TODO (IW): Merge w/ logic in LoginTemplate.tsx !!
    // route logged in user to default page
    if (router.pathname === "/") {
      let pathname = "/planner";

      if (!userState.user?.onboarded) {
        pathname = userState.isAuthenticated ? getUserOnboardingPath("welcome") : "/login";
      }

      void router.replace({ pathname, query: router.query });
      return;
    }
  }, [medium, router, router.isReady, router.query, userState.isAuthenticated, userState.status, userState.user]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* This modal provider is specifically for notifications that want to launch a modal */}
        <ModalProvider legacy>
          <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
            {/* This modal provider is what the rest of the app interacts with */}
            <ModalProvider legacy>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
                <Head>
                  <title>Reclaim – A smart friend for your calendar</title>
                  <meta name="robots" content="noindex" key="robots" />
                </Head>
                <CssBaseline />
                <Box className={classes.root}>
                  {/* 
                  * If any of the following, just display a loading screen.
                  - old state format
                  - a redirect in the query
                  - a route of /
                  - still loading the user or trying to
                  */}
                  <ReclaimErrorBoundary failureCode={4319}>
                    <GlobalStateInitializer>
                      <CommandBarContextProvider>
                        {isImpersonating && (
                          <Alert severity="error" className={classes.alert}>
                            <AlertTitle>Impersonation Active</AlertTitle>
                            <strong>YOU ARE IMPERSONATING ANOTHER USER!</strong> Any actions you take may affect their
                            account. Please be discrete and <strong>close this session when you are finished</strong>.
                          </Alert>
                        )}
                        <div className={classes.content}>{children}</div>
                      </CommandBarContextProvider>
                    </GlobalStateInitializer>
                  </ReclaimErrorBoundary>
                </Box>
                <DevDrawer />
                <EasyAccessElements />
              </LocalizationProvider>
            </ModalProvider>
          </SnackbarProvider>
        </ModalProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
